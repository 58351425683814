/* Footer.css */
.footer {
    background-color: transparent;
    color: #fff;
    text-align: center;
    padding: 10px;
    position: static;
    bottom: 0;
    width: 100%;
  }
  