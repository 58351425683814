.about-container {
  width: 80%;
  margin: auto;
  padding: 70px;
  font-family: Arial, sans-serif;
  text-align: center;
}

.about-container h1 {
  color: #333;
  font-size: 2em;
}

.about-container p {
  color: #666;
  line-height: 1.6;
}
