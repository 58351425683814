.AppBar {
    background-color: #666;
  }
  
  .navbar .title {
    margin-right: 20px;
    text-decoration: none;
    color: inherit;
  }
  
  .navbar .navLinks {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .navbar .navLink {
    margin-left: 20px;
    text-decoration: none;
    color: inherit;
  }
  
  @media (max-width: 600px) {
    .navbar .title {
      margin-right: 10px;
    }
  
    .navbar .navLink {
      margin-left: 10px;
    }
  }
  