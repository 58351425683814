/* whoisLookup.css */

.whois-lookup {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    background-color: #f8f9fa;
}

.input-container {
    display: flex;
    margin-bottom: 20px;
}

.input-container input {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px; 
    border: 1px solid #ced4da;
}

.input-container button {
    padding: 12px 24px;
    background-color: #ec5b06;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px; 
    margin-left: 10px; 
}

.input-container button:hover {
    background-color: #ec5b00;
}

.domain-info {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
    font-size: 18px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.domain-info p {
    border-bottom: 1px solid #cfcccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin: 5px 0;
}

.server-info {
    margin-bottom: 20px;
}

.raw-data {
    background-color: #f0f0f0;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.raw-data pre {
    white-space: pre-wrap;
    font-size: 14px;
    margin: 0;
    padding: 10px; 
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow-x: auto; 
}

.toggle-button {
    margin-top: 10px;
    border-radius: 5px;
    background-color: #007bff;
    color: white; 
    padding: 10px 20px; 
    font-size: 16px; 
    border: none; 
    cursor: pointer;
    transition: background-color 0.3s ease; 
}

.toggle-button:hover {
    background-color: #0056b3;
}

.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid #007bff; 
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin-left: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
