/* HomePage.css */

.homepage {
  background-image: url('background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px;
}

.homepage h1 {
  margin-top: 70px; 
  text-align: center;
  color: aliceblue;
}

.homepage h3 {
  margin-top: 10px;
  text-align: center;
  color: aliceblue;
}


.qa-heading {
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
}

.question {
  cursor: pointer;
  margin-bottom: 30px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  
}

.answer {
  padding: 10px;
  /* background-color: #fff; */
  border-radius: 4px;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
  padding: 15px;
  font-size: 18px;
}
